html, body { overflow-x: hidden; }
@import "settings";
@import "mixins";

/*****************************************************************************
min-width: 1430px
*****************************************************************************/
@media only screen and (min-width: 1430px) {

	.container {
		max-width: 1430px;
	}

}

/*****************************************************************************
1700px - 1800px
*****************************************************************************/
@media only screen and (min-width: 1700px) and (max-width: 1800px) {
	#menu > ul > li > a {
		padding: 0 22px;
	}
}

/*****************************************************************************
max-width: 1699px
*****************************************************************************/
@media only screen and (max-width: 1699px) {

	#Header {
		padding: 0 20px;
		.header-content {
			.left-col {
				a.logo {
					width: 100px;
					height: 114px;
				}
				h2 {
					font-size: 22px;
					line-height: 24px;
				}
			}
		}
	}

	#menu > ul > li > a {
		padding: 0 14px;
	}

	#contact-page-map .address-box {
		right: 57%;
	}

}

/*****************************************************************************
max-width: 1575px
*****************************************************************************/
@media only screen and (max-width: 1560px) {

}

/*****************************************************************************
max-width: 1429px
*****************************************************************************/
@media only screen and (max-width: 1429px) {

	#Header {
		padding: 0 20px;
		.header-content {
			.left-col {
				padding: 4px 20px 0 0;
				a.logo {
					width: 80px;
					height: 91px;
				}
				h2 {
					margin-left: 20px;
					font-size: 18px;
					line-height: 22px;
				}
			}
			.right-col .menu-bar {
				.social-icon {
					margin-left: 15px;
					padding-left: 15px;
				}
				.visually-impaired {
					padding-left: 15px;
					margin-left: 15px;
				}
			}
		}
	}

	#menu > ul > li {
		font-size: 16px;
		& > a {
			padding: 0 10px;
		}
	}

	#slider-main-section {
		.slider-main-content {
			width: 760px;
			.slider-main .slide {
				.photo {
					height: 417px;
				}
				.desc {
					h2 {
						margin-right: 25px;
						font-size: 34px;
					}
				}
			}
			.bx-controls {
				bottom: 90px;
				right: 50px;
			}
		}
		.right-col {
			.rsb {
				margin-bottom: 17px;
				padding-top: 25px;
				height: 200px;
				a img {
					max-width: 200px;
				}
			}
			.mini-slider-box {
				height: 200px;
				overflow: hidden;
				.slide .desc {
					top: 52px;
					h4 {
						font-weight: 700;
					}
				}
			}
		}
	}

	#news {
		.items .item {
	    width: 32%;
			a {
				.photo {
					height: 218px;
				}
				.desc {
					margin-top: 25px;
					p {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.news-subpage .items .item {
			margin-right: 22px;
		}
	}

	#video .inside {
		.video-list {
			width: 800px;
			.items .item {
				width: 32%;
				height: 197px;
			}
		}
		.green-box {
			width: 290px;
			height: 298px;
			padding-top: 40px;
			h3 {
				font-size: 30px;
			}
			p {
				margin-bottom: 22px;
			}
		}
	}

	#partners .subsidy {
		margin-left: 140px;
	}

	#Footer .menu .inside .menu-list li {
		padding: 0 20px;
	}

	.subpage-simple {
		margin: 40px 0 90px;
		.section-photo {
	    .left-col {
	      width: 560px;
	      height: 433px;
	      margin-bottom: 0;
	      .photo {
					width: 480px;
					height: 361px;
	      }
	      .border-photo {
					width: 475px;
					height: 370px;
					left: 45px;
					border-width: 10px;
	      }
	    }
	    .right-col {
	      padding-top: 32px;
	    }
	  }
	}

	#contact-page-map {
		.city {
			width: 690px;
		}
		.address-box {
			width: 550px;
			height: 380px;
			top: 93px;
			right: 57%;
			padding: 73px 65px 0;
			h4 {
				font-size: 22px;
			}
			p {
				font-size: 17px;
			}
		}
	}

	.subpage-blog-details {
		margin: 60px 0 90px;
		.inside {
			.blog-details {
				width: 67%;
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 26px;
						line-height: 30px;
					}
				}
				img {
					margin: 20px 0;
				}
			}
			.blog-sidebar {
				width: 30%;
				aside {
					margin-bottom: 20px;
					h3 {
						margin-bottom: 30px;
						padding-bottom: 15px;
						font-size: 18px;
						line-height: 21px;
					}
				}
				.widget-latest-news {
					.items {
						.item a {
							.desc {
								width: 230px;
								p {
									&.title {
										font-size: 14px;
										line-height: 17px;
									}
								}
							}
						}
					}
				}

			}
		}
	}

	.subpage-gallery .items .item {
		a {
			width: 262px;
			height: 200px;
		}
	}

	.list-of-members .items .item {
		margin-right: 11px;
		&:nth-child(5n) {
			margin-right: 11px;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}

	.exam-applications .items .item {
		width: 100%;
	}

	.catalog .top {
		.filtration a.all {
			padding-left: 17px;
			padding-right: 17px;
		}
		.search {
			width: 340px;
		}
	}

	.subpage-video .inside .video-list .items .item {
		margin-right: 20px;
		width: 262px;
		height: 201px;
	}

}

/*****************************************************************************
max-width: 1199px
*****************************************************************************/
@media only screen and (max-width: 1199px) {

	#nav-icon {
		display: block;
	}
	#menu {
		display: none;
	}

	#Header, #Header.sticky {
		padding: 0 30px;
		.header-content {
			align-items: center;
			.left-col {
				padding-top: 0;
				a.logo {
					width: 64px;
					height: 73px;
				}
				h2 {
					margin-left: 15px;
					line-height: 20px;
				}
			}
			.right-col {
				.top-bar {
					display: none;
				}
				.menu-bar {
					border-top: 0;
				}
			}
		}
	}

	#Content {
    padding-top: 86px;
	}

	h3.line {
		font-size: 26px;
	}

	.text-line {
		h3 {
			font-size: 28px;
			line-height: 28px;
		}
		.links span {
			margin: 0 20px 0 18px;
		}
	}

	.headline-links h3 {
		font-size: 30px;
		line-height: 34px;
	}

	#slider-main-section .inside {
		flex-wrap: wrap;
		padding-bottom: 30px;
		.slider-main-content {
			width: 100%;
			.slider-main .slide {
				.photo {
					height: 510px;
				}
				.desc h2 {
					font-size: 32px;
				}
			}
		}
		.right-col {
			width: 100%;
			margin-top: 25px;
			display: flex;
			justify-content: space-between;
			.rsb {
				width: 580px;
				margin-bottom: 0;
				@include border-radius(5px);
			}
			.mini-slider-box {
				width: 323px;
			}
		}
	}

	#news {
		margin-top: 55px;
		.items .item a {
			.photo {
			    height: 183px;
			}
			.desc {
				margin-top: 20px;
				p {
					margin-bottom: 15px;
					font-size: 17px;
					line-height: 23px;
				}
			}
		}
		&.news-subpage .items .item {
			margin-right: 18px;
			margin-bottom: 50px;
		}
	}

	#video {
		margin-bottom: 70px;
		.inside {
			.video-list {
				width: 650px;
				h3 {
					font-size: 30px;
					line-height: 30px;
				}
				.items .item {
					height: 160px;
				}
			}
			.green-box {
				width: 260px;
				height: 257px;
				padding-top: 34px;
				h3 {
					font-size: 26px;
				}
				h4 {
					margin-bottom: 15px;
				}
				.line {
					margin-bottom: 18px;
				}
				p {
					margin-bottom: 18px;
					font-size: 17px;
					line-height: 22px;
				}
				.more {
					font-size: 15px;
				}
			}
		}
	}

	#partners {
		.inside {
			margin-top: 60px;
		}
		.slider-partners-content .slide img {
			max-width: 80%;
		}
		.side-tags {
			margin-top: 65px;
		}
		.subsidy {
			margin-left: 60px;
		}
	}

	#bottom-info .items .item {
		h5 {
			font-size: 16px;
		}
		p {
			margin-bottom: 20px;
			font-size: 15px;
		}
		ul li {
			font-size: 15px;
		}
	}

	#Footer {
		padding-top: 20px;
		.social-links .logo {
			display: none;
			top: -20px;
			a {
				width: 70px;
				height: 80px;
			}
		}
		.menu .inside {
			height: 110px;
			.menu-list li {
				padding: 0 15px;
			}
		}
		.signature .inside {
			height: inherit;
			padding: 20px 0 5px;
			flex-wrap: wrap;
			justify-content: center;
			div {
				width: 100%;
			}
			p {
				margin-bottom: 10px;
				text-align: center;
			}
		}
	}

	.subpage-simple {
		margin: 30px 0 80px;
		.section-photo {
			flex-wrap: wrap;
			.left-col {
				width: 100%;
				height: 400px;
				margin-bottom: 30px;
				.photo {
					width: 480px;
					height: 361px;
					left: 180px;
				}
				.border-photo {
					width: 475px;
					height: 370px;
					left: 225px;
					border-width: 10px;
				}
			}
			.right-col {
				padding-top: 0;
				width: 100%;
			}
		}
	}

	#contact-page-map {
		height: 480px;
		.city {
			width: 610px;
		}
		#map {
			height: 480px;
		}
		.address-box {
			padding: 35px 37px 0;
			width: 420px;
			height: 280px;
			right: 62%;
			h4 {
				font-size: 18px;
				line-height: 22px;
			}
			h5 {
				font-size: 15px;
			}
			p {
				margin-bottom: 15px;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	.subpage-contact {
		margin: 70px 0;
		.inside {
			.left-col {
				width: 48%;
				padding-right: 30px;
				h3 {
					font-size: 22px;
				}
				p {
					font-size: 17px;
				}
			}
			.right-col {
				width: 52%;
				padding-left: 40px;
				h3 {
					margin-bottom: 30px;
					font-size: 26px;
					line-height: inherit;
				}
			}
		}
	}

	.files-sb h3 {
		font-size: 25px;
	}

	.subpage-blog-details {
		margin: 50px 0 60px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 22px;
						line-height: 28px;
					}
				}
			}
			.blog-sidebar {
				aside {
					h3 {
						margin-bottom: 30px;
						padding-bottom: 10px;
						font-size: 16px;
						line-height: 20px;
					}
				}
				.widget-latest-news .items .item a {
					padding-bottom: 12px;
					.desc {
						width: 177px;
					}
				}
			}
		}
	}

	.subpage-gallery .items .item {
		&:nth-child(4n) {
			margin-right: 20px;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		a {
			width: 296px;
			height: 226px;
		}
	}

	.card-header .btn {
		font-size: 16px;
	}

	.list-of-members {
		padding: 50px 0 10px;
		.headline-links h3 {
			font-size: 26px;
			line-height: 28px;
		}
		.items .item {
			width: 224px;
			padding: 25px 22px 25px;
			.photo {
				width: 180px;
				height: 180px;
			}
			.desc {
				margin-top: 20px;
				h6 {
					margin-bottom: 3px;
					font-size: 18px;
					line-height: 18px;
				}
				p {
					font-size: 14px;
					line-height: 14px;
				}
			}
		}
	}

	.exam-applications .headline-links h3 {
		font-size: 26px;
		line-height: 28px;
	}

	.catalog .top {
		margin-top: 55px;
		.search {
			width: 100%;
			margin-left: 0;
		}
	}

	.subpage-video .inside .video-list .items .item {
		width: 296px;
		height: 227px;
		&:nth-child(4n) {
			margin-right: 20px;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

}


/*****************************************************************************
max-width: 991px
*****************************************************************************/
@media only screen and (max-width: 991px) {

	.responsive-block-open #Wrapper {
		left: 0;
	}
	#responsive-block {
		left: -260px;
		width: 260px;
	}

	#Header, #Header.sticky {
		padding: 0 30px;
		.header-content {
			.left-col {
				a.logo {
					width: 59px;
					height: 67px;
				}
				h2 {
					font-size: 16px;
					line-height: 18px;
				}
			}
			.right-col .menu-bar {
				height: 80px;
			}
		}
	}

	#Content {
		padding-top: 80px;
	}

	h3.line {
    font-size: 24px;
	}

	.text-line {
		h3 {
			font-size: 26px;
			line-height: 26px;
		}
		.line {
			margin-top: 0;
		}
		.links {
			font-size: 14px;
			span {
				margin: 0 10px 0 12px;
			}
		}
	}

	.headline-links h3 {
		font-size: 26px;
		line-height: 30px;
	}

	#slider-main-section .inside {
		padding-bottom: 30px;
		.slider-main-content {
			.slider-main .slide {
				.photo {
					height: 378px;
				}
				.desc {
					width: 440px;
					height: 140px;
					bottom: 40px;
					left: -50px;
					h2 {
						margin-left: 50px;
						font-size: 26px;
					}
				}
			}
		}
		.right-col {
			margin-top: 25px;
			.rsb {
				width: 345px;
			}
			.mini-slider-box {
				width: 323px;
			}
		}
	}

	#news {
		.items .item a {
			.photo {
				height: 135px;
			}
			.desc {
				margin-top: 15px;
				p {
					font-size: 16px;
					line-height: 22px;
				}
			}
		}
		&.news-subpage .items .item {
			margin-right: 13px;
		}
	}

	#video {
		margin-bottom: 62px;
		.inside {
			.video-list {
				width: 440px;
				h3 {
					margin-bottom: 40px;
					font-size: 26px;
					line-height: 26px;
				}
				.items {
					.item {
						&:nth-child(3) {
							display: none;
						}
						width: 49%;
						height: 166px;
					}
				}
			}
			.green-box {
				width: 235px;
				height: 246px;
				.line {
					width: 90%;
				}
				p {
					font-size: 16px;
					line-height: 20px;
				}
				.more {
					font-size: 14px;
				}
			}
		}
	}

	#partners {
		.inside {
			margin-top: 50px;
		}
		.side-tags {
			margin-top: 50px;
			padding: 40px 0 35px;
			h5 {
				margin-bottom: 10px;
				font-size: 18px;
			}
		}
		.subsidy {
			margin-left: 0;
			padding: 70px 0 70px 190px;
			h4 {
				font-size: 16px;
			}
		}
	}

	#bottom-info {
		padding: 60px 0 15px;
	  .items {
	    padding: 0 33px 0 40px;
			flex-wrap: wrap;
			text-align: center;
	    .item {
				margin-bottom: 30px;
	      &:first-child {
	        width: 49%;
					order: 1;
	      }
	      &:nth-child(2) {
	        width: 49%;
					order: 3;
	      }
	      &:nth-child(3) {
	        width: 49%;
					order: 4;
	      }
	      &:last-child {
	        width: 49%;
					order: 2;
					h5, ul {
						text-align: center;
					}
	      }
				h5 br {
					display: none;
				}
				 ul.disc li {
					 padding-left: 0;
					 &:before {
						 display: none;
					 }
				 }
	    }
	  }
	}

	#Footer {
		padding-top: 15px;
		.social-links .inside {
			justify-content: center;
		}
		.menu .inside {
			height: inherit;
			padding: 20px 0;
			.menu-list {
				flex-wrap: wrap;
				justify-content: center;
				li {
					padding: 10px 20px;
				}
			}
		}
		.signature p br {
			display: block;
		}
	}

	.subpage-simple {
		margin: 25px 0 70px;
		.section-photo {
			.left-col {
				height: 360px;
				margin-bottom: 30px;
				.photo {
					width: 410px;
					height: 308px;
					left: 105px;
				}
				.border-photo {
					width: 405px;
					height: 324px;
					left: 155px;
				}
			}
		}
	}

	#contact-page-map {
		height: 340px;
		.city {
			display: none;
		}
		#map {
			height: 340px;
			width: 100%;
		}
		.address-box {
			display: none;
		}
	}

	.subpage-contact {
		margin: 50px 0 40px;
		.inside {
			flex-wrap: wrap;
			.left-col {
				width: 100%;
				border-right: 0;
				padding-right: 0;
				margin-bottom: 30px;
				.resp {
					display: block;
				}
			}
			.right-col {
				width: 100%;
				padding-left: 0;
				h3 {
					padding-bottom: 5px;
					border-bottom: 1px solid #d0d1d3;
					font-size: 22px;
				}
			}
		}
	}

	.files-sb {
		h3 {
			font-size: 22px;
		}
		.items .item a {
			padding-left: 72px;
			font-size: 17px;
		}
	}

	.subpage-blog-details {
		margin: 40px 0;
		.inside {
			flex-wrap: wrap;
			.blog-details {
				width: 100%;
			}
			.blog-sidebar {
				width: 100%;
				.widget-latest-news .items .item a {
					padding-bottom: 15px;
					margin-bottom: 15px;
					justify-content: flex-start;
					.photo {
						width: 85px;
						margin-right: 20px;
					}
					.desc {
						width: inherit;
						flex: 1;
						p.date {
							margin-top: 0;
						}
					}
				}
			}
		}
	}

	.subpage-gallery {
		margin-top: 50px;
		.items {
			justify-content: space-between;
			.item {
				margin-bottom: 25px;
				margin-right: 0;
				&:nth-child(4n) {
					margin-right: 0;
				}
				a {
					width: 334px;
					height: 254px;
				}
			}
		}
	}

	.card-header {
		padding-left: 15px;
		padding-right: 15px;
	}
	.card-body {
		padding: 30px 30px;
	}

	.list-of-members {
		padding: 40px 0 10px;
		.headline-links h3 {
			font-size: 24px;
			line-height: 26px;
		}
		.items {
			margin-bottom: 40px;
			.item {
				width: 222px;
				margin-bottom: 20px;
				padding: 25px 21px 25px;
				&:nth-child(4n){
					margin-right: 11px;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
			}
		}
	}

	.exam-applications {
		padding: 40px 0 0;
		.headline-links {
			margin-bottom: 40px;
			h3 {
				font-size: 24px;
				line-height: 26px;
			}
		}
	}

	.catalog {
		.top .filtration a {
			font-size: 15px;
		}
		.table td {
			font-size: 14px;
		}
	}

	.subpage-video {
		margin-top: 50px;
		.inside .video-list .items {
			justify-content: space-between;
			.item {
				width: 328px;
				height: 252px;
				margin-right: 0;
				margin-bottom: 25px;
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 767px
*****************************************************************************/
@media only screen and (max-width: 767px) {

	h3.line {
		font-size: 22px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	.text-line {
		h3 {
			margin-right: 15px;
			font-size: 24px;
			line-height: 24px;
		}
		.links {
			margin-left: 15px;
			span {
				margin: 0 7px 0 9px;
			}
		}
	}

	.headline-links {
		h3 {
			font-size: 22px;
			line-height: 24px;
		}
		a.more {
			padding-right: 25px;
		}
	}

	#slider-main-section .inside {
		padding-bottom: 30px;
		.slider-main-content {
			.slider-main .slide {
				.photo {
					height: 280px;
				}
				.desc {
					width: 380px;
					height: inherit;
					padding: 30px 0;
					bottom: 40px;
					left: -50px;
					h2 {
						margin-left: 50px;
						font-size: 22px;
					}
				}
			}
			.bx-controls {
				bottom: 20px;
				right: 30px;
			}
		}
		.right-col {
			margin-top: 20px;
			.rsb {
				width: 248px;
				height: 207px;
				padding-top: 35px;
				a {
					img {
						max-width: 180px;
					}
					.more {
						font-size: 15px;
					}
				}
			}
			.mini-slider-box {
				width: 248px;
				height: 207px;
				.slide {
					.photo img {
						max-width: 100%;
					}
					.desc {
						top: 63px;
						left: 80px;
						h4 {
							font-size: 17px;
						}
						.more {
							font-size: 15px;
						}
					}
				}
			}
		}
	}

	#news {
		margin: 45px 0 60px;
		.items .item {
			width: 49%;
			&:nth-child(3) {
				display: none;
			}
			a {
				.photo {
					height: 154px;
				}
				.desc {
					margin-top: 15px;
					p {
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
		&.news-subpage .items {
			justify-content: space-between;
			.item {
				margin-right: 0;
				margin-bottom: 40px;
			}
		}
	}

	#video {
		margin-bottom: 50px;
		.inside {
			flex-wrap: wrap;
			.video-list {
				width: 100%;
				h3 {
					margin-bottom: 30px;
					padding-bottom: 10px;
					font-size: 22px;
					line-height: 22px;
				}
				.items {
					.item {
						width: 49%;
						height: 192px;
					}
				}
			}
			.green-box {
				margin: 30px auto 0;
				padding: 49px 20px 0;
				width: 324px;
				height: 310px;
				h3 {
					font-size: 32px;
				}
				h4 {
					margin-bottom: 20px;
				}
				.line {
					width: 207px;
				}
				p {
					font-size: 18px;
					line-height: 24px;
				}
				.more {
					font-size: 17px;
				}
			}
		}
	}

	#partners {
		.inside {
	    margin-top: 40px;
		}
		.side-tags {
		    margin-top: 40px;
		    padding: 30px 0 25px;
		}
		.subsidy {
			padding: 60px 0 60px 180px;
			background-size: 140px;
			h4 {
				font-size: 15px;
				line-height: 25px;
				br {
					display: none;
				}
			}
		}
	}

	#bottom-info {
		padding: 50px 0 0px;
	}

	#Footer .menu .inside {
		padding: 15px 0;
		.menu-list li {
			padding: 0;
			font-size: 16px;
			a {
				display: block;
				padding: 10px 20px;
			}
		}
	}

	.subpage-simple {
		margin: 25px 0 60px;
		.section-photo {
			.left-col {
				height: 340px;
				margin-bottom: 30px;
				.photo {
					width: 400px;
					height: 300px;
					left: 20px;
				}
				.border-photo {
					width: 400px;
					height: 306px;
					left: 55px;
				}
			}
		}
	}

	#contact-page-map {
		height: 300px;
		#map {
			height: 300px;
		}
	}

	.subpage-contact {
		margin-top: 40px;
		.inside .left-col {
			margin-bottom: 25px;
			h3 {
				font-size: 20px;
			}
			h4 {
				margin-bottom: 30px;
				font-size: 16px;
			}
		}
	}

	.files-sb {
		h3 {
			padding-bottom: 10px;
			font-size: 20px;
		}
		.items .item a {
			padding-left: 65px;
			font-size: 16px;
		}
	}

	.subpage-blog-details .inside {
		.blog-details {
			.blog-details__header {
				margin-bottom: 20px;
				h3 {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}

	.subpage-gallery {
		margin-top: 40px;
		.items {
			.item {
				margin-bottom: 20px;
				a {
					width: 246px;
					height: 188px;
				}
			}
		}
	}

	.card-header {
		padding-left: 10px;
		padding-right: 10px;
	}
	.card-body {
		margin-top: 15px;
		padding: 30px 25px 20px;
	}

	.list-of-members {
		.headline-links {
			margin-bottom: 25px;
			h3 {
				font-size: 20px;
				line-height: 20px;
			}
		}
		.items {
			justify-content: space-between;
			margin-bottom: 34px;
			.item {
				margin-right: 0;
				width: 48%;
				&:nth-child(4n), &:nth-child(5n) {
					margin-right: 0;
				}
				.photo {
					width: 203px;
					height: 203px;
				}
			}
		}
	}

	.exam-applications {
		padding: 40px 0 0;
		.headline-links {
			margin-bottom: 30px;
			h3 {
				font-size: 20px;
				line-height: 20px;
			}
		}
		.items .item {
			.box-border a {
				padding: 40px 40px 40px 69px;
				font-size: 19px;
			}
			a.file {
				font-size: 16px;
			}
		}
	}

	.catalog {
		padding: 0 0 60px;
		.top {
			margin: 45px 0 40px;
		}
		.table thead th {
			font-size: 14px;
		}
	}

	.subpage-video {
		margin-top: 40px;
		.inside .video-list .items {
			.item {
				width: 246px;
				height: 189px;
				margin-bottom: 20px;
			}
		}
	}

}

/*****************************************************************************
max-width: 575px
*****************************************************************************/
@media only screen and (max-width: 575px) {

	#responsive-block {
		width: 250px;
		left: -250px;
	}

	#Header, #Header.sticky {
		padding: 0 15px;
		.header-content {
			.left-col {
				a.logo {
					width: 52px;
					height: 60px;
				}
				h2 {
					font-size: 14px;
					line-height: 16px;
				}
			}
			.right-col .menu-bar {
				height: 70px;
			}
		}
	}

	#Content {
		padding-top: 70px;
	}

	.text-line h3 {
    font-size: 20px;
    line-height: 20px;
	}

	.headline-links {
		margin-bottom: 20px;
		padding-bottom: 10px;
		h3 {
	    font-size: 20px;
	    line-height: 22px;
		}
		a.more {
			display: none;
		}
	}

	#slider-main-section {
		.container {
			padding: 0;
		}
		.inside {
			padding-bottom: 20px;
			.slider-main-content .slider-main .slide {
				.photo {
					height: 263px;
					overflow: hidden;
				}
				.desc {
					left: 0;
					width: 320px;
					h2 {
						margin: 0 20px;
						font-size: 18px;
					}
				}
			}
			.right-col {
				margin: 20px 15px 0;
				.rsb {
					width: 220px;
					height: 183px;
					padding-top: 35px;
					a {
						img {
							max-width: 160px;
						}
						.more {
							font-size: 14px;
						}
					}
				}
				.mini-slider-box {
					width: 220px;
					height: 183px;
					.slide {
						.photo img {
							max-width: 100%;
						}
						.desc {
							top: 50px;
							left: 75px;
							h4 {
								font-size: 15px;
							}
							.more {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	#news {
		margin: 40px 0 50px;
		.items {
			justify-content: center;
			.item {
				width: 218px;
				&:first-child {
					margin-right: 10px;
				}
				a {
					.photo {
				    height: 134px;
					}
					.desc {
						margin-top: 10px;
					}
				}
			}
		}
		&.news-subpage {
			margin: 30px 0 50px;
			.items {
				.item {
					margin-bottom: 30px;
					width: 49%;
					&:first-child {
						margin-right: 0;
					}
					 a .photo img {
						 height: auto;
					 }
				}
			}
		}
	}

	#video .inside {
		.video-list {
			h3 {
				margin-bottom: 20px;
				font-size: 20px;
				line-height: 20px;
			}
			.items {
				justify-content: center;
				.item {
					width: 218px;
					height: 167px;
					&:first-child {
						margin-right: 10px;
					}
				}
			}
		}
		.green-box {
			height: 270px;
			margin-top: 25px;
			h3 {
				font-size: 28px;
			}
			p {
				font-size: 16px;
				line-height: 20px;
			}
		}
	}

	#partners {
		.inside {
			margin-top: 30px;
		}
		.side-tags {
			margin-top: 30px;
		}
		.subsidy {
			padding: 50px 0 50px 145px;
			background-size: 120px;
		}
	}

	.subpage-simple {
		margin: 20px 0 50px;
		.section-photo {
			.left-col {
				height: 290px;
				margin-bottom: 20px;
				.photo {
					width: 360px;
					height: 250px;
					left: 50%;
					margin-left: -180px;
				}
				.border-photo {
					left: 50%;
					width: 360px;
					height: 250px;
					margin-left: -150px;
				}
			}
		}
	}

	.files-sb {
		margin: 40px 0 50px;
		h3 {
			margin-bottom: 20px;
			padding-bottom: 8px;
			font-size: 17px;
		}
		.items .item {
			&.pdf, &.doc {
				background-size: 38px;
			}
			a {
				padding: 12px 0 12px 55px;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.subpage-blog-details {
		margin: 35px 0 20px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
					h3 {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
			.blog-sidebar {
				aside h3 {
					margin-bottom: 20px;
				}
				.widget-latest-news .items .item a .desc p.date {
					margin-top: 0;
				}
			}
		}
	}

	.subpage-gallery {
		margin-top: 30px;
		.items {
			.item {
				margin-bottom: 20px;
				width: 48%;
				a {
					width: 100%;
					height: 164px;
				}
			}
		}
	}

	.card-header .btn {
    font-size: 14px;
	}
	.card-body {
		padding-top: 25px;
	}

	.list-of-members {
		padding: 30px 0 0px;
		.items .item {
			padding: 25px 0;
			.photo {
		    width: 180px;
		    height: 180px;
				margin: 0 auto;
			}
			.desc {
				padding: 0 20px;
			}
		}
	}

	.exam-applications {
		padding: 30px 0 0;
		.headline-links {
			margin-bottom: 30px;
			h3 {
				font-size: 20px;
				line-height: 20px;
			}
		}
		.items {
			margin-bottom: 50px;
			.item {
				margin-bottom: 30px;
				.box-border a {
					padding: 35px 35px 35px 60px;
					font-size: 17px;
				}
				a.file {
					font-size: 15px;
				}
			}
		}
	}

	.catalog .top {
		margin-top: 30px;
		.filtration a {
			margin: 0 10px 12px 0;
		}
	}

	.subpage-video {
		margin: 30px 0;
		.inside .video-list .items {
			.item {
				width: 216px;
				height: 166px;
				margin-bottom: 20px;
			}
		}
	}

}

/*****************************************************************************
max-width: 479px
*****************************************************************************/
@media only screen and (max-width: 479px) {

	h3.line {
		font-size: 20px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	.text-line {
		h3 {
			margin-right: 10px;
		}
		.links {
			margin-left: 5px;
			span {
				margin: 0 5px 0 7px;
			}
		}
	}

	#Header .header-content .right-col .menu-bar {
		.social-icon {
			padding-left: 10px;
			a {
				margin-left: 8px;
				&.fb, &.yt {
					width: 25px;
					height: 26px;
					background-size: 100%;
				}
			}
		}
		.visually-impaired {
			padding-left: 12px;
			margin-left: 10px;
			a.vi {
				width: 26px;
				height: 26px;
				background-size: 100%;
			}
		}
	}

	#slider-main-section .inside {
		.slider-main-content .slider-main .slide .photo {
			height: 230px;
		}
		.right-col {
			margin: 20px 15px 0;
			.rsb {
				width: 190px;
				height: 158px;
				padding-top: 25px;
				a {
					img {
						margin-bottom: 14px;
						max-width: 140px;
					}
					.more {
						font-size: 13px;
					}
				}
			}
			.mini-slider-box {
				width: 190px;
				height: 158px;
				.slide {
					.desc {
						top: 40px;
						left: 64px;
						h4 {
							font-size: 14px;
						}
						.more {
							font-size: 13px;
						}
					}
				}
				.bx-controls {
					bottom: 17px;
					right: 18px;
				}
			}
		}
	}

	#news {
		margin: 30px 0 45px;
		.items {
			.item {
				width: 190px;
				&:first-child {
					margin-right: 10px;
				}
				a {
					.photo {
				    height: 117px;
					}
				}
			}
		}
		&.news-subpage {
			margin: 25px 0 50px;
		}
	}

	#video .inside .video-list .items .item {
		height: 146px;
		a .play {
			width: 34px;
			height: 38px;
			top: calc(50% - 17px);
			left: calc(50% - 19px);
			background-size: 100%;
		}
	}

	#partners .subsidy {
		padding: 40px 0 40px 130px;
		background-size: 110px;
		h4 {
			font-size: 14px;
			line-height: 23px;
		}
	}

	#bottom-info .items .item {
		&:nth-child(2), &:nth-child(3) {
			width: 100%;
		}
	}

	.subpage-simple .section-photo .left-col .border-photo {
		margin-left: -160px;
		top: 20px;
	}

	.subpage-contact {
		margin: 30px 0;
		.inside {
			.left-col {
				margin-bottom: 15px;
				h3 {
					font-size: 18px;
				}
				h4 {
					font-size: 15px;
				}
			}
			.right-col h3 {
				font-size: 20px;
			}
		}
	}

	.subpage-blog-details {
		margin: 25px 0 15px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
				}
				img {
					margin: 15px 0;
				}
			}
			.blog-sidebar .widget-latest-news .items .item a .photo {
				margin-right: 15px;
			}
		}
	}

	.subpage-gallery .items .item a {
		height: 142px;
	}

	.list-of-members {
		padding: 30px 0 0px;
		.headline-links h3 {
			font-size: 17px;
			line-height: 19px;
		}
		.items {
			margin-bottom: 25px;
			.item {
				padding: 20px 0;
				.photo {
					width: 160px;
					height: 160px;
				}
				.desc {
					padding: 0 15px;
				}
			}
		}
	}

	.exam-applications {
		padding: 30px 0 0px;
		.headline-links h3 {
			font-size: 17px;
			line-height: 19px;
		}
		.items {
			margin-bottom: 40px;
			.item {
				margin-bottom: 25px;
				.box-border {
					a {
						padding: 30px 30px 30px 50px;
					}
					span.bg {
						width: 60px;
						height: 72px;
						background-size: 100%;
					}
				}
			}
		}
	}

	.subpage-video {
		margin: 30px 0;
		.inside .video-list .items {
			justify-content: center;
			.item {
				width: 328px;
				height: inherit;
				margin-bottom: 20px;
			}
		}
	}

}

/*****************************************************************************
max-width: 419px
*****************************************************************************/
@media only screen and (max-width: 419px) {

	h3.line {
		font-size: 18px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	.text-line h3 {
		font-size: 20px;
	}

	#nav-icon {
		order: 2;
	}

	#Header .header-content .right-col .menu-bar {
		.social-icon {
			display: none;
		}
		.visually-impaired {
			order: 1;
			margin: 0 10px 0 0;
			padding-left: 0;
			background: none;
		}
	}

	#slider-main-section .inside {
		.slider-main-content {
			.slider-main .slide {
				.photo {
					height: 175px;
				}
				.desc {
					width: 250px;
					padding: 20px 0;
					bottom: 15px;
					h2 {
						font-size: 16px;
					}
				}
			}
			.bx-controls {
				bottom: 7px;
				right: 15px;
			}
		}
		.right-col {
			flex-wrap: wrap;
			justify-content: center;
			.rsb {
				width: 290px;
				margin: 0 auto 15px;
			}
			.mini-slider-box {
				width: 290px;
				.slide .desc {
					left: 110px;
					h4 {
						font-size: 17px;
					}
					.more {
						font-size: 15px;
					}
				}
			}
		}
	}

	#news {
		margin-bottom: 20px;
		.items {
			flex-wrap: wrap;
			.item {
				margin-bottom: 20px;
				width: 290px;
				&:first-child {
					margin-right: 0;
				}
				a {
					.photo {
						height: 178px;
					}
					.desc {
						p {
							margin-bottom: 5px;
						}
					}
				}
			}
		}
		&.news-subpage .items .item {
			width: 100%;
			a .photo {
				height: 100%;
			}
		}
	}

	#video .inside {
		.video-list .items {
			flex-wrap: wrap;
			.item {
				margin-bottom: 15px;
				width: 290px;
				height: 222px;
				&:first-child {
					margin-right: 0;
				}
			}
		}
		.green-box {
			margin-top: 5px;
			h3 {
				font-size: 24px;
			}
		}
	}

	#partners .subsidy {
		margin-top: 20px;
		padding: 105px 0 20px;
		background-position: center 0;
		h4 {
			text-align: center;
		}
	}

	#bottom-info {
		padding-top: 40px;
		.items .item {
			margin-bottom: 25px;
			&:first-child, &:last-child {
				width: 100%;
			}
			&:last-child {
				order: 5;
			}
			h5 {
				margin-bottom: 10px;
			}
		}
	}

	#Footer .menu .inside .menu-list li {
		width: 100%;
		text-align: center;
	}

	.subpage-simple {
		margin: 20px 0 40px;
		.section-photo {
			.left-col {
				height: 222px;
				margin-bottom: 30px;
				.photo {
					width: 260px;
					height: 195px;
					margin-left: -130px;
				}
				.border-photo {
					border-width: 8px;
					width: 260px;
					height: 195px;
					margin-left: -112px;
					top: 18px;
				}
			}
		}
	}

	#contact-page-map {
		height: 280px;
		#map {
			height: 280px;
		}
	}

	.subpage-contact .inside {
		.left-col h3 {
	    font-size: 16px;
		}
		.right-col h3 {
			margin-bottom: 20px;
			font-size: 18px;
		}
	}

	.contact-form .bottom-form {
		display: flex;
		justify-content: center;

	}

	.subpage-gallery .items .item {
		width: 100%;
		a {
			height: inherit;
			&:hover .bg {
				display: none;
			}
		}
	}

	.card-header {
		padding-left: 5px;
		padding-right: 5px;
	}
	.card-body {
		margin-top: 15px;
		padding: 20px 20px 20px;
	}

	.list-of-members {
		padding: 20px 0 0px;
		.headline-links {
			margin-bottom: 20px;
		}
		.items {
			margin-bottom: 25px;
			.item {
				padding: 30px 0;
				width: 100%;
				.photo {
					width: 203px;
					height: 203px;
				}
				.desc {
					padding: 0 25px;
				}
			}
		}
	}

	.exam-applications .items .item {
		.border-box {
			a {
				padding: 25px 22px 25px 38px;
				font-size: 16px;
				line-height: 18px;
			}
			span.bg {
				width: 50px;
				height: 60px;
			}
		}
	}

	.subpage-video .inside .video-list .items .item {
		width: 100%;
		height: inherit;
	}

}
